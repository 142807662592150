import { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import add from '../../../assets/images/add.svg';
import AddTank from './AddTank';
import api from '../../../api/index';
import TankType from './Tank Type';
import TankSize from './Tank Size';
import TankMaterial from './Tank Material';
import TankFilter from './Tank Filter';

export default function Tanks() {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [tanks, setTanks] = useState([]);
  const getTankDetails = async () => {
    const { data } = await api('get', '/tank');
    if (data) {
      setTanks(data?.tanks);
    }
  };
  const getType = (t) => {
    setType(t);
  };

  useEffect(() => {
    getTankDetails();
  }, []);
  return (
    <div>
      <Grid container>
        <Grid xl={2} lg={3} />

        <Grid
          xl={10}
          lg={9}
          sx={{
            backgroundColor: '#d9f4ff',
            height: '100%',
            paddingBottom: '3%',
          }}
        >
          <TankSize
            tanks={tanks}
            getTanks={getTankDetails}
            type={type}
            setType={setType}
          />
          <TankType
            tanks={tanks}
            getTanks={getTankDetails}
            type={type}
            setType={setType}
          />
          <TankMaterial
            tanks={tanks}
            getTanks={getTankDetails}
            type={type}
            setType={setType}
          />
          <TankFilter
            tanks={tanks}
            getTanks={getTankDetails}
            type={type}
            setType={setType}
          />
          <div className="btnContainer" style={{ position: 'fixed' }}>
            <Button
              variant="contained"
              fullWidth
              className="addAdminBtn"
              onClick={() => setOpen(true)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', // Align items to the start
                // position: ' ',
                gap: '18px',
                width: '25%', // Initial width for the button
                overflow: 'hidden',
                transition: 'width 0.3s', // Smooth width transition
                padding: '12px 14px !important', // Adjust padding for button content
                minWidth: 'unset',
                '& img': {
                  width: '24px', // Fixed size for the image
                  height: '24px',
                  transition: 'all 0.3s ease',
                  // marginRight: '8px', // Space between image and text
                },
                '& .text': {
                  opacity: 0, // Text is hidden initially
                  display: 'none',
                  whiteSpace: 'nowrap',
                  // transform: 'translateX(20px)', // Initially shifted to the right
                  transition:
                    'opacity 0.3s ease, transform 0.3s ease',
                  // transform: 'translateX(-120%)', // Move text into view from the right
                },
                '&:hover': {
                  width: '100%',
                  // left: 'unset',
                  transition: ' all 0.2s ease',
                },
                '&:hover .text': {
                  opacity: 1, // Reveal the text on hover
                  transform: 'translateX(10%)', // Move text into view from the right
                  display: 'block',
                },
              }}
            >
              <span className="text">Add New Value &nbsp;</span>
              <img
                src={add}
                alt="add"
                style={{ marginRight: '3px' }}
              />
            </Button>
          </div>
        </Grid>
      </Grid>

      {open && (
        <AddTank
          open={open}
          setOpen={setOpen}
          getTanks={getTankDetails}
          getType={getType}
        />
      )}
    </div>
  );
}

import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ServiceTable from './ServiceTable';
import api from '../../../api/index';
import ExportServiceHistory from '../../../components/ExportCsv';

export default function Services() {
  const [serviceHistory, setServiceHistory] = useState([]);
  const [exportServiceHistory, setExportServiceHistory] = useState(
    [],
  );
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const exportHistory = async (e) => {
    setExportServiceHistory(e);
  };

  useEffect(() => {
    const getServiceHistory = async () => {
      const { data } = await api(
        'get',
        `/schedule/tasks?page=${page}&limit=20`,
      );
      if (data) {
        setServiceHistory([...data.results]);
        setTotal(data.total);
      } else {
        // Handle the case where data or data.results is undefined
        setServiceHistory([]); // or any other default action
      }
    };
    getServiceHistory();
  }, [page]);
  return (
    <div>
      <Grid container>
        <Grid xl={2} lg={3} />
        <Grid xl={10} lg={9}>
          <ServiceTable
            serviceHistory={serviceHistory}
            exportHistory={exportHistory}
            page={page}
            setPage={setPage} // Pass down setPage to handle pagination
            total={total} // Pass total for pagination calculation
          />
          <ExportServiceHistory
            serviceHistory={exportServiceHistory}
          />
        </Grid>
      </Grid>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';

import ProductTable from './ProductTable';
import add from '../../../assets/images/add.svg';
import AddProduct from './AddProduct';

import api from '../../../api/index';

export default function Products() {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [shapaka, setShapaka] = useState({
    page: 1,
    next: null,
    prev: null,
    totalPages: 1,
  });
  const getProducts = async () => {
    const { data } = await api('get', `/products?page=${page}`);
    const { results, ...shapakaItems } = data;
    if (results?.length > 0) {
      setShapaka(shapakaItems);
      setProducts(results);
    } else {
      // eslint-disable-next-line
      console.log('No more data available');
    }
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, [page]);
  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };
  return (
    <div>
      <Grid container>
        <Grid xl={2} lg={3} />

        <Grid xl={10} lg={9}>
          <ProductTable
            products={products}
            getProducts={getProducts}
            handleLoadMore={handleLoadMore}
            shapaka={shapaka}
          />
          <div className="btnContainer">
            <Button
              variant="contained"
              fullWidth
              className="addAdminBtn"
              onClick={() => setOpen(true)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', // Align items to the start
                // position: ' ',
                gap: '18px',
                width: '25%', // Initial width for the button
                overflow: 'hidden',
                transition: 'width 0.3s', // Smooth width transition
                padding: '12px 14px !important', // Adjust padding for button content
                minWidth: 'unset',
                '& img': {
                  width: '24px', // Fixed size for the image
                  height: '24px',
                  transition: 'all 0.3s ease',
                  // marginRight: '8px', // Space between image and text
                },
                '& .text': {
                  opacity: 0, // Text is hidden initially
                  display: 'none',
                  whiteSpace: 'nowrap',
                  // transform: 'translateX(20px)', // Initially shifted to the right
                  transition:
                    'opacity 0.3s ease, transform 0.3s ease',
                  // transform: 'translateX(-120%)', // Move text into view from the right
                },
                '&:hover': {
                  width: '100%',
                  // left: 'unset',
                  transition: ' all 0.2s ease',
                },
                '&:hover .text': {
                  opacity: 1, // Reveal the text on hover
                  transform: 'translateX(10%)', // Move text into view from the right
                  display: 'block',
                },
              }}
            >
              <span className="text">Add Product &nbsp; &nbsp;</span>
              <img
                src={add}
                alt="add"
                style={{ marginRight: '3px' }}
              />
            </Button>
          </div>
        </Grid>
      </Grid>
      {open && (
        <AddProduct
          open={open}
          setOpen={setOpen}
          getProducts={getProducts}
        />
      )}
    </div>
  );
}

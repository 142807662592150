/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-underscore-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable no-constant-condition */
/* eslint-disable consistent-return */
import { React, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
// import { toast } from 'react-toastify';
import {
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import Checkbox from '@mui/material/Checkbox';
import api from '../../../../api/index';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-root': {
    borderRadius: '25px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(7),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: 'center',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(7),
    margin: 'auto',
  },
}));
const useOutlinedInputStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #CDEEFC',
        borderRadius: '20px',
        fontSize: 16,
      },
      '&:hover fieldset': {
        borderColor: '#28CDFF',
      },

      '&.Mui-focused fieldset': {
        borderColor: '#28CDFF',
      },
      '&.Mui-disabled fieldset': {
        borderColor: ' #CDEEFC',
      },
    },
  },
});

export default function ReviewChecklist({
  open,
  setOpen,
  checklistType,
  onSubmit,
  loading,
}) {
  const outlinedInputStyles = useOutlinedInputStyles();
  const [checklistArray, setChecklistArray] = useState([]);
  const [inputCheckList, setInputCheckList] = useState('');
  const [userAddedList, setUserAddedList] = useState([]);

  const checkListData = async () => {
    try {
      const res = await api(
        'get',
        `/checklist?type=${checklistType}`,
      );
      setChecklistArray(
        res?.data?.results.map((e) => ({ ...e, checked: false })),
      );
    } catch (err) {
      // err
    }
  };
  useEffect(() => {
    checkListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistType]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddClick = () => {
    if (inputCheckList.trim() !== '') {
      setUserAddedList([...userAddedList, inputCheckList]);
      setInputCheckList('');
    }
  };
  const handleRemoveItem = (indexToRemove) => {
    const updatedList = userAddedList.filter(
      (item, index) => index !== indexToRemove,
    );
    setUserAddedList(updatedList);
  };

  const handleCheckClick = (index, value) => {
    setChecklistArray((e) => {
      e[index].checked = value;
      return e;
    });
  };

  const handleChange = () => {
    const data = [
      checklistArray
        .filter((e) => e.checked)
        .map(({ title, description, attachment }) => ({
          title,
          description,
          attachment,
        })),
      userAddedList.map((e) => ({ title: e })),
    ].flat();
    onSubmit(data);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        setOpen={setOpen}
        fullWidth
        maxWidth="md"
        id="addtask"
        PaperProps={{
          style: {
            borderRadius: 25,
            background: '#F3FDFF',
            maxHeight: '650px',
            overflowY: 'scroll',
          },
        }}
      >
        <DialogContent>
          <Typography gutterBottom>
            <h1
              style={{
                fontSize: '24px',
                color: '#28CDFF',
                paddingBottom: '20px',
              }}
            >
              Review Checklist
            </h1>
            <FormControl
              sx={{
                mb: 2,
                width: '60%',
              }}
            >
              <TextField
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '25px',
                  padding: '0px',
                }}
                id="outlined-email"
                classes={outlinedInputStyles}
                placeholder="New Checklist"
                size="normal"
                value={inputCheckList}
                name="inputCheckList"
                onChange={(e) => setInputCheckList(e.target.value)}
                inputProps={{
                  style: {
                    height: '15px',
                    color: 'black',
                  },
                }}
              />
            </FormControl>
            <FormControl
              sx={{
                mb: 2,
                width: '20%',
                marginLeft: '2%',
              }}
            >
              <Button
                variant="contained"
                className="checkListBtns"
                onClick={handleAddClick}
              >
                Add
              </Button>
            </FormControl>
            {userAddedList?.length > 0 &&
              userAddedList?.map((item, index) => (
                <>
                  <FormControl
                    key={index?.id}
                    sx={{
                      mb: 2,
                      width: '60%',
                    }}
                  >
                    <TextField
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '25px',
                        padding: '0px',
                      }}
                      id="outlined-email"
                      classes={outlinedInputStyles}
                      size="normal"
                      value={item}
                      inputProps={{
                        style: {
                          height: '15px',
                          color: 'black',
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    sx={{
                      mb: 2,
                      width: '20%',
                      marginLeft: '2%',
                    }}
                  >
                    <Button
                      variant="contained"
                      className="removeBtn"
                      onClick={() => handleRemoveItem(index)}
                    >
                      Remove
                    </Button>
                  </FormControl>
                </>
              ))}
            {checklistArray?.length > 0 &&
              checklistArray?.map((item, index) => (
                <FormControl
                  key={index?.id}
                  sx={{
                    mb: 2,
                    width: '80%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      gap: '0px',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      // defaultChecked
                      sx={{ color: '#28cdff' }}
                      onChange={(_, v) => handleCheckClick(index, v)}
                    />
                    <p
                      style={{
                        color: 'black',
                        fontSize: '17px',
                        fontWeight: 400,
                        fontFamily: 'Helvetica Neue',
                      }}
                    >
                      {item?.title}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '87%',
                      margin: 'auto',
                    }}
                  >
                    <p
                      style={{
                        color: '#8C8C8C',
                        fontSize: '14px',
                        fontWeight: 300,
                        fontFamily: 'Helvetica Neue',
                      }}
                    >
                      {item?.description}
                    </p>
                  </div>
                </FormControl>
              ))}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            className="closeBtn"
            variant="outlined"
          >
            Close
          </Button>
          <Button
            onClick={handleChange}
            variant="contained"
            className="resetPasswordBtn"
            disabled={loading}
            sx={{
              width: loading ? '130px' : 'fit-content',
              height: loading ? '50px' : 'fit-content',
            }}
          >
            {loading ? (
              <CircularProgress size={20} sx={{ color: '#fff' }} />
            ) : (
              'Save Checklist'
            )}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

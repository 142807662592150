import { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';

import ClientTable from './ClientTable';
import add from '../../../../assets/images/add.svg';
import AddClient from './AddClient';
import api from '../../../../api/index';

export default function Clients() {
  const [open, setOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const getClients = async () => {
    setLoading(true);
    const { data } = await api('get', '/clients');
    if (data) {
      setClients(data?.results);
      setLoading(false);
    }
  };

  useEffect(() => {
    getClients();
  }, []);
  return (
    <div>
      <Grid container>
        <Grid xl={2} lg={3} />

        <Grid xl={10} lg={9}>
          <ClientTable
            loading={loading}
            clients={clients}
            getClients={getClients}
          />
          <div className="btnContainer">
            <Button
              variant="contained"
              fullWidth
              className="addAdminBtn"
              onClick={() => setOpen(true)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', // Align items to the start
                // position: ' ',
                gap: '18px',
                width: '25%', // Initial width for the button
                overflow: 'hidden',
                transition: 'width 0.3s', // Smooth width transition
                padding: '12px 14px !important', // Adjust padding for button content
                minWidth: 'unset',
                '& img': {
                  width: '24px', // Fixed size for the image
                  height: '24px',
                  transition: 'all 0.3s ease',
                  // marginRight: '8px', // Space between image and text
                },
                '& .text': {
                  opacity: 0, // Text is hidden initially
                  display: 'none',
                  whiteSpace: 'nowrap',
                  // transform: 'translateX(20px)', // Initially shifted to the right
                  transition:
                    'opacity 0.3s ease, transform 0.3s ease',
                  // transform: 'translateX(-120%)', // Move text into view from the right
                },
                '&:hover': {
                  width: '100%',
                  // left: 'unset',
                  transition: ' all 0.2s ease',
                },
                '&:hover .text': {
                  opacity: 1, // Reveal the text on hover
                  transform: 'translateX(10%)', // Move text into view from the right
                  display: 'block',
                },
              }}
            >
              <span className="text">Add new Client &nbsp;</span>

              <img
                src={add}
                alt="add"
                style={{ marginRight: '3px' }}
              />
            </Button>
          </div>
        </Grid>
      </Grid>
      {open && (
        <AddClient
          open={open}
          setOpen={setOpen}
          getClients={getClients}
        />
      )}
    </div>
  );
}

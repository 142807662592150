import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import api from '../api/index';
import delConfirm from '../assets/images/delConfirm.svg';

// eslint-disable-next-line object-curly-newline

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-root': {
    borderRadius: '25px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(7),
    textAlign: 'center',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    margin: 'auto',
  },
}));

export default function DeleteModal({
  open,
  setOpen,
  item,
  getData,
  txt,
  selectedDate,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  console.log('txt', txt);
  const handleDelete = async () => {
    await api('delete', `/${txt}/${item._id}`);
    if (txt === 'schedule/tasks') {
      getData(
        // eslint-disable-next-line operator-linebreak
        moment(selectedDate).format('MM-DD-YYYY') ||
          moment(new Date()).format('MM-DD-YYYY'),
      );
    } else {
      getData();
    }
    toast.success('Deleted successfully');
    handleClose();
  };
  const handleDeleteAll = async () => {
    await api('delete', `/${txt}/${item._id}?future=true`);
    if (txt === 'schedule/tasks') {
      getData(
        // eslint-disable-next-line operator-linebreak
        moment(selectedDate).format('MM-DD-YYYY') ||
          moment(new Date()).format('MM-DD-YYYY'),
      );
    } else {
      getData();
    }
    toast.success('Deleted successfully');
    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        setOpen={setOpen}
        PaperProps={{
          style: {
            width: '100%',
            borderRadius: 25,
            background: '#F3FDFF',
          },
        }}
      >
        <DialogContent>
          <Typography gutterBottom>
            <img src={delConfirm} alt="" />
            <h1
              style={{
                fontSize: '24px',
                color: '#28CDFF',
                marginTop: '40px',
              }}
            >
              You&apos;re deleting an event.
            </h1>

            <p className="enterEmailTxt">
              Do you want to delete this and all future occurances of
              this event, or only the selected occurance.
            </p>
            <div className="hl-del" />
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
          }}
        >
          <Button
            onClick={handleDelete}
            className="delBtn"
            variant="contained"
            sx={{
              width: '90%',
            }}
          >
            Delete Only This Event
          </Button>
          <Button
            onClick={handleDeleteAll}
            className="closeBtn"
            variant="outlined"
            sx={{
              width: '90%',
              // marginLeft: '0 !important',
              // bgcolor: 'lightgray !important',
              // color: 'gray !important',
            }}
          >
            Delete All Future Events
          </Button>
          <Button
            onClick={handleClose}
            className="closeBtn"
            variant="outlined"
            sx={{
              width: '90%',
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

/* eslint-disable react/no-unstable-nested-components */
import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import filter from '../../../../assets/images/filter.png';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect({
  text,
  style,
  sx,
  setSelectedFilter,
}) {
  const [Filter, setFilter] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setFilter(event.target.value);
    setOpen(false);
    setSelectedFilter(event.target.value);
  };
  const handleToggleDropdown = () => {
    setOpen((prev) => !prev);
  };
  return (
    <div>
      <FormControl sx={sx} size="small">
        <Select
          displayEmpty
          value={Filter}
          onChange={handleChange}
          input={<OutlinedInput />}
          style={style}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          sx={{ textAlign: 'left', width: '260px' }}
          aria-hidden="true"
          IconComponent={() => (
            <div
              onClick={handleToggleDropdown}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleToggleDropdown();
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                marginRight: '10px',
              }}
            >
              <KeyboardArrowDownOutlinedIcon />
              <img src={filter} style={{ margin: '0 2%' }} alt="" />
            </div>
          )}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="">
            {text}
          </MenuItem>
          <MenuItem value="client">Client Name</MenuItem>
          <MenuItem value="tankSize">Tank Size</MenuItem>
          <MenuItem value="date">Date</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
